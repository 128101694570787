<template>
  <BasicModal v-if="modalEnabled" />
</template>

<script>
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { computed, ref, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'

export default {
  name: 'LeaveEarlyModal',
  components: { BasicModal },
  props: {
    valuesToCheck: {
      type: Array,
      default: () => []
    },
    customModalContent: {
      type: Object,
      default: null
    },
    resultCallback: {
      type: Function,
      default: () => {}
    },
  },
  setup(props) {
    const store = useStore()

    const modalController = ref(new ModalController())
    const activeModal = computed(() => store.getters.getActiveBasicModal)
    const modalEnabled = computed(() =>
      activeModal.value === MODAL_NAMES.LEAVE_EARLY
    )


    function isEmpty(value) {
      return !value || value === ''
    }


    watch(() => props.valuesToCheck, (curr, prev) => {
      if (curr !== prev) {
        if (!curr || curr.length === 0) {
          modalController.value.resetActiveModal()
        } else {
          const allEmpty = curr.every(toCheck => isEmpty(toCheck))
          if (allEmpty) {
            props.resultCallback(true)
          } else {
            modalController.value.setModal(
              BASIC_MODAL_CONTENT.CANCEL_EARLY,
              () => returnResult(true),
              () => returnResult(false),
              null, MODAL_NAMES.LEAVE_EARLY)
          }
        }
      }
    })

    function returnResult(result) {
      modalController.value.resetActiveModal()
      props.resultCallback(result)
    }

    return {
      modalEnabled
    }
  }
}
</script>
<style>

</style>