<template>
  <div class="content-container">
    <PageTitle :title="$t('INSTRUCTIONS.TITLE_CREATE_TOP')" class="page-title"/>

    <h1 class="page-section-title section-title">{{ $t('INSTRUCTIONS.EDIT') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" @click="attemptLeave()"/>

    <!-- Form -->
    <FormWrapper v-if="originalData"
                 :send-form-data="validateData"
                 class="page-form"
                 :on-form-data-update="updatedForm">
      <template #form-content>
        <!-- Title -->
        <InputField :field-title="$t('INSTRUCTIONS.TITLE_CREATE')"
                    :field-name="'title'"
                    :rules="'required'"
                    :type="'text'"
                    :placeholder="$t('INSTRUCTIONS.TITLE_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :api-error-text="''"
                    :value="originalData.title"
                    class="form-element"/>

        <!-- Instruction -->
        <!-- <InputField :field-title="$t('INSTRUCTIONS.INSTRUCTION')"
                    :field-name="'content'"
                    :rules="'required'"
                    :type="'text'"
                    :placeholder="$t('INSTRUCTIONS.INSTRUCTION_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :api-error-text="''"
                    class="form-element"/> -->

        <div class="rich-text-field-wrapper">
          <RichTextField :field-title="$t('INSTRUCTIONS.INSTRUCTION')"
                         :input-callback="instructionInput"
                         :value="originalData.content"
                         placeholder="Voer de instructie in"
          />
        </div>

        <!-- Status -->
        <Dropdown
          :field-title="$t('INSTRUCTIONS.STATUS')"
          :field-name="'status'"
          :dropdown-options="dropdownOptions"
          :placeholder="$t('INSTRUCTIONS.CONCEPT')"
          :callback="statusCallback"
          :selected-option="getStatusForID(originalData.isActivated)"
          class="form-element"/>
      </template>

      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t('SAVE') }}
        </button>
      </template>

    </FormWrapper>
    <LeaveEarlyModal :values-to-check="leaveEarlyValuesToCheck" :result-callback="leaveEarlyResult"/>

  </div>
</template>

<script>
import nl from '@/utils/language/nl.json'
import PageTitle from '@/components/elements/texts/PageTitle'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import BackButton from '@/components/elements/BackButton'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { EDIT_INSTRUCTION } from '@/store/modules/cms/actions'
import Dropdown from '../../../components/yo-form/Dropdown.vue'
import RichTextField from '@/components/elements/RichTextEditor/RichTextField'
import { ref } from '@vue/reactivity'
import ApiHandler from '@/utils/handlers/ApiHandler'
import { computed } from '@vue/runtime-core'
import LeaveEarlyModal from '@/components/elements/basicModal/LeaveEarlyModal'

export default {
  name: 'EditInstructions',
  components: {
    BackButton,
    FormWrapper,
    PageTitle,
    InputField,
    Dropdown,
    RichTextField,
    LeaveEarlyModal,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const instructionValue = ref('')

    const leaveEarlyValuesToCheck = ref([])
    const currentFormData = ref({})
    const originalData = ref(undefined)
    function updatedForm(data) {
      currentFormData.value = data
    }

    function attemptLeave() {
      const changedTitle = originalData.value.title !== currentFormData.value.title
      const changedText = instructionValue.value !== originalData.value.content
      const changedStatus = (originalData.value.isActivated === 1) !== (currentFormData.value.status === nl.INSTRUCTIONS.PUBLIC)

      leaveEarlyValuesToCheck.value = [changedTitle, changedText, changedStatus]
    }

    function leaveEarlyResult(value) {
      if (value) {
        router.push({ name: ROUTE_NAMES_CMS.INSTRUCTIONS })
      } else {
        leaveEarlyValuesToCheck.value = []
      }
    }



    const dropdownOptions = computed(() => {
      return [{ id: 0, name: nl.INSTRUCTIONS.CONCEPT }, { id: 1, name: nl.INSTRUCTIONS.PUBLIC }]
    })

    ApiHandler.get('instruction/get-instruction/' + route.params.id).then(response => {
      originalData.value = response.data
      instructionValue.value = originalData.value.content
    })

    /** Form **/
    function validateData(data) {
      data.content = instructionValue.value
      if (!data.content || data.content === '') {
        return false
      }

      data.isActivated = data.status === nl.INSTRUCTIONS.PUBLIC
      data.id = route.params.id
      store.dispatch(EDIT_INSTRUCTION, data).then(result => {
        if (result) {
          router.push({ name: ROUTE_NAMES_CMS.INSTRUCTIONS })
        }
      })
    }

    function instructionInput(value) {
      instructionValue.value = value
    }

    function statusCallback() {
    }

    function getStatusForID(id) {
      return dropdownOptions.value.find((status) => status.id === id)
    }

    return {
      ROUTE_NAMES_CMS,
      originalData,
      dropdownOptions,

      instructionInput,
      /** Form **/
      statusCallback,
      validateData,
      getStatusForID,
      leaveEarlyValuesToCheck,
      leaveEarlyResult,
      updatedForm,
      attemptLeave,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.address-columns {
  margin-bottom: rem(28);
}

.info-box {
  margin-bottom: rem(24);
  margin-top: rem(24);
}

.rich-text-field-wrapper {
  margin-bottom: rem(28);
}
</style>
